import React from 'react'
import Grid from '@material-ui/core/Grid'
import Image from './Image'

export default function Action({action, display}) {
  return (display === 'card') ? (
    (action.node.frontmatter.description != '' && action.node.frontmatter.description != null) ? (
      <Grid item xs={12} md={8}>
        <a href={"/actions"+action.node.fields.slug}>
          <h3>{action.node.frontmatter.year} - {action.node.frontmatter.title}</h3>
          <Image src={action.node.frontmatter.thumbnail} alt={action.node.frontmatter.title} title={action.node.frontmatter.title}/>
        </a>
      </Grid>
    ) : (
      <Grid item xs={12} md={8}>
        <h3>{action.node.frontmatter.year} - {action.node.frontmatter.title}</h3>
        <Image src={action.node.frontmatter.thumbnail} alt={action.node.frontmatter.title} title={action.node.frontmatter.title}/>
      </Grid> 
    )
  ) : (
    <Grid item xs={12} md={8}>
        <h3>{action.node.frontmatter.year} - {action.node.frontmatter.title}</h3>
    </Grid>
  );
}