import React from 'react'
import { StaticQuery, graphql } from "gatsby"
import Grid from '@material-ui/core/Grid'
import Action from '../beans/Action'

export default function ActionsList({data, limit, onlyCards}) {
  return (
    <StaticQuery 
      query={graphql`
        query Actions {
          allMarkdownRemark(filter: {frontmatter: {layout: {eq: "action"}, thumbnail: {}}}, sort: {order: DESC, fields: frontmatter___year}) {
            edges {
              node {
                frontmatter {
                  thumbnail
                  title
                  description
                  year(formatString: "YYYY", locale: "fr")
                }
                excerpt
                fields {
                  slug
                }
              }
            }
          }
        }
      `}
    render={data => (
      <Grid container>
        {data.allMarkdownRemark.edges.filter((action) => {return action.node.frontmatter.thumbnail !== null}).slice(0, limit).map((action, id) => (
          <Grid className="card" key={id} item xs={12} md={4}>
            <Action action={action} display="card"/>
          </Grid>
        ))}
        {onlyCards === 'true' ? '' : data.allMarkdownRemark.edges.filter((action) => {return action.node.frontmatter.thumbnail === null}).slice(0, limit).map((action, id) => (
          <Grid className="card" key={id} item xs={12} md={12}>
            <Action action={action} display="list"/>
          </Grid>
        ))}
      </Grid>
    )}
    />
  )
}
